@import 'import/core.scss';
@import 'import/layout.scss';
@import 'import/utils.scss';
@import 'import/measurements.scss';
@import 'import/colors.scss';
@import 'import/router.scss';

body .ant-form-item-label > label {
    margin-bottom: 0;
}

.linkButton {
    background-color: transparent;
    border: none;
    cursor: pointer;
    text-decoration: none;
    display: inline;
    margin: 0;
    padding: 0;
    
    &:hover,
    &:focus {
        text-decoration: none;
        outline: none;
    }
}
