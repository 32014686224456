// Import mixins
@import '../../mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN CHECKBOX */


.ant-checkbox-input.ant-checkbox-inner, .ant-checkbox-wrapper > .ant-checkbox-inner, .ant-checkbox > .ant-checkbox-inner {
  border-color: #d9d9d9 !important;
}
span.ant-checkbox > .ant-checkbox-inner {
  background-color: white !important;
}

span.ant-checkbox-checked > .ant-checkbox-inner {
  background-color: #FFC107 !important;
}

span.ant-checkbox-checked:hover > .ant-checkbox-inner{
  border-color: #FFC107 !important;
}

span.ant-checkbox-checked:hover > input.ant-checkbox:hover{
  border-color: #FFC107 !important;
}

.ant-checkbox-indeterminate .ant-checkbox-inner::after {

  background-color: #FFC107 !important;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: #FFC107 !important;
}
.ant-checkbox-checked::after {
  border: 1px solid #FFC107 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #FFC107;
  border-color: #FFC107;
}


