// Import mixins
@import '../../mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN LIST */


ul.ant-list-items {
  margin-left: 0;
  color: red !important;
  width: 100%;
}

li.ant-list-item {
  margin-left: 0 !important;
}

li.ant-list-item.a {

}

h4.ant-list-item-meta-title a{
  color: #9f9f9f !important;
font-size: 0.93rem;
}

li.ant-list-item:hover {
  margin-left: 0 !important;
  background-color: #413c3c;
  cursor: pointer;
}