@import '../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* CAROUSEL */

.carousel-indicators {
  li {
    opacity: 0.5;
    &.active {
      opacity: 1;
    }
  }
}
