// Import mixins
@import '../../mixins.scss';

/////////////////////////////////////////////////////////////////////////////////////////
/*  ANT DESIGN TABLE */

.table {
  .ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters.ant-table-align-center {
    background-color:#ffb90c !important;
    text-align: left !important;
    cursor: pointer;
  }
  .ant-table-column-has-actions.ant-table-column-has-filters.ant-table-align-center {
    background-color:#ffb90c !important;
    text-align: left !important;
    cursor: pointer;
  }
  .ant-table-align-center {
    background-color:#ffb90c !important;
    text-align: left !important;
    cursor: pointer;
  }
  .ant-table-thead {
    background-color:#ffb90c !important;
    text-align: left !important;
    cursor: pointer;
  }
  .ant-table-row.ant-table-row-level-0:hover {
    background-color:#ffb90c !important;
    text-align: left !important;
    cursor: pointer;
  }
  td.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters
  {
    text-align: left !important;
    cursor: pointer;
  }
  td.ant-table-column-has-actions.ant-table-column-has-sorters, td {
    text-align: left !important;
    cursor: pointer;
  }

  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: #ffdf5d !important;
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
    color: rgba(0, 0, 0, 0.45);
    background-color: #dc960c !important;
  }
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
    color: rgba(0, 0, 0, 0.45);
    background-color: #dc960c !important;
  }
}

.tableArchive {
  .ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters.ant-table-align-center {
    background-color: rgba(66, 66, 66, 0.18) !important;
    text-align: left !important;
    cursor: pointer;
  }
  .ant-table-column-has-actions.ant-table-column-has-filters.ant-table-align-center {
    background-color: rgba(66, 66, 66, 0.18) !important;
    text-align: left !important;
    cursor: pointer;
  }
  .ant-table-align-center {
    background-color: rgba(66, 66, 66, 0.18) !important;
    text-align: left !important;
    cursor: pointer;
  }
  .ant-table-thead {
    background-color: rgba(66, 66, 66, 0.18) !important;
    text-align: left !important;
    cursor: pointer;
  }
  .ant-table-row.ant-table-row-level-0:hover {
    background-color: rgba(66, 66, 66, 0.18) !important;
    text-align: left !important;
    cursor: pointer;
  }
  td.ant-table-column-has-actions.ant-table-column-has-filters.ant-table-column-has-sorters
  {
    text-align: left !important;
    cursor: pointer;
  }
  td.ant-table-column-has-actions.ant-table-column-has-sorters, td {
    text-align: left !important;
    cursor: pointer;
  }
  .ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-thead > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td,
  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td {
    background-color: rgba(66, 66, 66, 0.18) !important;
  }

  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .anticon-filter.ant-table-filter-open,
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-filters .ant-table-filter-icon.ant-table-filter-open {
    color: rgba(0, 0, 0, 0.45);
    background-color: rgba(66, 66, 66, 0.18) !important;
  }
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .anticon-filter,
  .ant-table-thead > tr > th.ant-table-column-has-actions.ant-table-column-has-sorters:hover .ant-table-filter-icon {
    color: rgba(0, 0, 0, 0.45);
    background-color: rgba(66, 66, 66, 0.18) !important;
  }
}

.ant-table-row.tableArchiveFirst.ant-table-row-level-0 {
  background-color: rgba(179, 179, 179, 0.18) !important;
}
.ant-table-row.tableArchiveSecond.ant-table-row-level-0 {
  background-color: rgba(118, 118, 118, 0.18) !important;
}

.ant-table-row.table.ant-table-row-level-0 {
  background-color:#fff6cc !important;
}

.ant-table-row.tableShared.ant-table-row-level-0 {
  background-color:#c1d9ff  !important;
}

.ant-table-thead > tr > th .anticon-filter,
.ant-table-thead > tr > th .ant-table-filter-icon {
  color: black !important;
}
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner {
  color: black !important;
}

.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-up.on,
.ant-table-thead > tr > th .ant-table-column-sorter .ant-table-column-sorter-inner .ant-table-column-sorter-down.on {
  color:red !important;
}


