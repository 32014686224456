@import '../../mixins';

/////////////////////////////////////////////////////////////////////////////////////////
/* TOOLTIPS */

.tooltip {
  .tooltip-inner {
    background: $black;
    padding: rem(4) rem(15);
    color: $white;
    font-size: rem(14);
  }
  &.left .tooltip-inner:before,
  &.bs-tooltip-auto[x-placement^='left'],
  &.bs-tooltip-left .arrow:before {
    border-left-color: $black;
  }
  &.right .tooltip-inner:before,
  &.bs-tooltip-auto[x-placement^='right'],
  &.bs-tooltip-right .arrow:before {
    border-right-color: $black;
  }
  &.top .tooltip-inner:before,
  &.bs-tooltip-auto[x-placement^='top'],
  &.bs-tooltip-top .arrow:before {
    border-top-color: $black;
  }
  &.bottom .tooltip-inner:before,
  &.bs-tooltip-auto[x-placement^='bottom'],
  &.bs-tooltip-bottom .arrow:before {
    border-bottom-color: $black;
  }
}
